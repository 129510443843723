import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/App/constants';
import { RemoteSessionApi } from 'app/redux/modules/RemoteSession/v2/api';
import { ApplicationApi } from 'app/redux/modules/App/api';
import HelpSection from 'components/modules/layout/Footer/HelpSection';

export default connector(STORE_NAME, (state) => ({
  ...state.props,
  remoteSession: RemoteSessionApi.isHandshakeComplete(state),
  applicationId: ApplicationApi.getCurrentApplication(state),
}))(HelpSection);
