import { DIALOG_ERROR_OPEN, DIALOG_OPEN, DIALOG_CLOSE } from './constants';

export const openErrorGlobalDialog = (message) => ({
  type: DIALOG_ERROR_OPEN,
  message,
});

export const openGlobalDialog = (title, message) => ({
  type: DIALOG_OPEN,
  title,
  message,
});

export const closeGlobalDialog = () => ({
  type: DIALOG_CLOSE,
});
