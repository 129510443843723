import { call, put, select, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';
import { trackEvent } from 'app/redux/modules/Analytics/actions';

import { postApplicationCompleteSuccess } from '../App/actions';
import getAccountNumber from '../../services/getAccountNumber';
import { PROCESS_3DS_PAYMENT_SUCCESS } from './constants';

export function* handlePaymentSuccess() {
  try {
    // will need to call get account number
    const { data } = yield call(getAccountNumber);
    const accountNumber = get(data, 'value');
    if (accountNumber) {
      const appSubmittedState = yield select(
        analyticsSelectors[EVENTS.APPLICATION_SUBMITTED]
      );
      yield put(trackEvent(EVENTS.APPLICATION_SUBMITTED, appSubmittedState));
      yield put(postApplicationCompleteSuccess(data));
    } else {
      // eslint-disable-next-line
      console.warn('no account number found');
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log('error calling getAccountNumber', error);
  }
}

export function* combinedSagas() {
  yield takeLatest(PROCESS_3DS_PAYMENT_SUCCESS, handlePaymentSuccess);
}
