import { takeLatest, put } from 'redux-saga/effects';

import { ELIGIBILITY_CHECK_REQUEST } from './constants';
import * as actions from './actions';

function* handleEligibilityCheck() {
  yield put(actions.checkIfEligibleSuccess());
}

export function* combinedSagas() {
  yield takeLatest(ELIGIBILITY_CHECK_REQUEST, handleEligibilityCheck);
}
