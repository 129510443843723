import Immutable from 'immutable';
import {
  sendingRequestState,
  fetchingRequestState,
  successRequestState,
  failedRequestState,
  createRequestStateObject,
} from 'redux/utils/isRequestInProgress';

import {
  CONFIRM_SELECTION_REQUEST,
  CONFIRM_SELECTION_SUCCESS,
  CONFIRM_SELECTION_FAILURE,
  ELIGIBILITY_CHECK_REQUEST,
  ELIGIBILITY_CHECK_SUCCESS,
  ELIGIBILITY_CHECK_FAILURE,
} from './constants';

const REQ_STATE_CONFIRM_SELECTION = 'confirmSelection';
const REQ_STATE_ELIGIBILITY_CHECK = 'eligibilityCheck';

const initialState = Immutable.fromJS({
  benefit: null,
  isEligible: false,
  ...createRequestStateObject([
    REQ_STATE_CONFIRM_SELECTION,
    REQ_STATE_ELIGIBILITY_CHECK,
  ]),
});

export default function GoldBenefitReducer(state = initialState, action) {
  switch (action.type) {
    case ELIGIBILITY_CHECK_REQUEST:
      return fetchingRequestState(state, REQ_STATE_ELIGIBILITY_CHECK);

    case ELIGIBILITY_CHECK_SUCCESS:
      return successRequestState(state, REQ_STATE_ELIGIBILITY_CHECK).mergeDeep({
        isEligible: action.eligibility,
      });

    case ELIGIBILITY_CHECK_FAILURE:
      return failedRequestState(state, REQ_STATE_ELIGIBILITY_CHECK).mergeDeep({
        error: action.error,
        isEligible: false,
        benefit: null,
      });

    case CONFIRM_SELECTION_REQUEST:
      return sendingRequestState(state, REQ_STATE_CONFIRM_SELECTION).mergeDeep({
        benefit: action.selection,
      });

    case CONFIRM_SELECTION_SUCCESS:
      return successRequestState(state, REQ_STATE_CONFIRM_SELECTION).mergeDeep({
        benefit: action.selection,
      });

    case CONFIRM_SELECTION_FAILURE:
      return failedRequestState(state, REQ_STATE_CONFIRM_SELECTION).mergeDeep({
        error: action.error,
        isEligible: false,
        benefit: null,
      });

    default:
      return state;
  }
}
