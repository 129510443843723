import { connector } from 'redux/utils/connector';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import FingerPrints from 'components/modules/FingerprintsWithChallenge';

import { STORE_NAME } from './constants';

export default connector(STORE_NAME, (state) => ({
  verificationUrl: state.get(STORE_NAME).toJS().verificationUrl,
  remote: {
    active: ApplicationApi.isRemoteSession(state),
    isMainPeerDriving: RemoteSessionApi.isMainPeerDriving(state),
  },
}))(FingerPrints);
