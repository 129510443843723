export const STORE_NAME = 'GoldBenefit';

export const CONFIRM_SELECTION_REQUEST = 'CONFIRM_SELECTION_REQUEST';
export const CONFIRM_SELECTION_FAILURE = 'CONFIRM_SELECTION_FAILURE';
export const CONFIRM_SELECTION_SUCCESS = 'CONFIRM_SELECTION_SUCCESS';
export const ELIGIBILITY_CHECK_REQUEST = 'ELIGIBILITY_CHECK_REQUEST';
export const ELIGIBILITY_CHECK_SUCCESS = 'ELIGIBILITY_CHECK_SUCCESS';
export const ELIGIBILITY_CHECK_FAILURE = 'ELIGIBILITY_CHECK_FAILURE';

export const BENEFIT_HOME_PHONE_BROADBAND = 'HOMEPHONE_BROADBAND';
