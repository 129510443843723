import Immutable from 'immutable';
import { POST_FUNDING_PAYMENT_CARD_SUCCESS } from '../PaymentForm/constants';

const initialState = Immutable.fromJS({
  verificationUrl: '',
  remote: {
    active: false,
    isPartner: false,
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_FUNDING_PAYMENT_CARD_SUCCESS: {
      return state.merge({
        verificationUrl: action.verificationUrl,
      });
    }
    default:
      return state;
  }
}
