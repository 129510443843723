import {
  CONFIRM_SELECTION_REQUEST,
  CONFIRM_SELECTION_FAILURE,
  CONFIRM_SELECTION_SUCCESS,
  ELIGIBILITY_CHECK_REQUEST,
  ELIGIBILITY_CHECK_SUCCESS,
  ELIGIBILITY_CHECK_FAILURE,
} from './constants';

export const confirmSelection = (selection) => ({
  type: CONFIRM_SELECTION_REQUEST,
  selection,
});
export const confirmSelectionSuccess = () => ({
  type: CONFIRM_SELECTION_SUCCESS,
});
export const confirmSelectionFailure = (error) => ({
  type: CONFIRM_SELECTION_FAILURE,
  error,
});
export const checkIfEligible = () => ({ type: ELIGIBILITY_CHECK_REQUEST });
export const checkIfEligibleSuccess = (eligibility) => ({
  type: ELIGIBILITY_CHECK_SUCCESS,
  eligibility,
});
export const checkIfEligibleFailure = (error) => ({
  type: ELIGIBILITY_CHECK_FAILURE,
  error,
});
