import {
  PROCESS_3DS_PAYMENT_SUCCESS,
  PROCESS_3DS_PAYMENT_FAILURE,
} from './constants';

export const processPaymentSuccess = () => ({
  type: PROCESS_3DS_PAYMENT_SUCCESS,
});

export const processPaymentFailure = () => ({
  type: PROCESS_3DS_PAYMENT_FAILURE,
});
