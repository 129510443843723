import Immutable from 'immutable';
import { DIALOG_ERROR_OPEN, DIALOG_OPEN, DIALOG_CLOSE } from './constants';

const initialState = Immutable.fromJS({
  GlobalDialog: {
    open: false,
    title: '',
    message: '',
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case DIALOG_ERROR_OPEN:
      return state.mergeDeep({
        GlobalDialog: {
          open: true,
          title: 'Error',
          message: action.message || '',
        },
      });

    case DIALOG_OPEN:
      return state.mergeDeep({
        GlobalDialog: {
          open: true,
          title: action.title || '',
          message: action.message || '',
        },
      });

    case DIALOG_CLOSE:
      return state.mergeDeep({
        GlobalDialog: {
          open: false,
        },
      });

    default:
      return state;
  }
}
